<template>
  <div>
    <AInput v-for="(item, index) of state" :key="name + index" v-bind="$attrs" v-on="listeners" :name="item_name + index" v-model="state[index]" :array="index" :remove="removeItem" :isValid="isValid(item_name + index)" :errors="errors(item_name + index)" />
    <div class="text-right">
      <CButton :disabled="!canAdd()" class="text-right" @click="addItem()"><CIcon name="cil-plus"/></CButton>
    </div>
    <div v-if="!!duplicates.length" class="text-danger">{{ `Existen elementos duplicados [${duplicates.join(', ')}]` }}</div>
  </div>
</template>

<script>
export default {
  name: 'AInputArray',
  inheritAttrs: true,
  props: {
    items: {
      type: Array,
      required: true
    },

    isValid: {
      type: Function,
      required: true
    },
    errors: {
      type: Function,
      required: true
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    last() {
      return this.state.length - 1
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.onChange(event)
      }
    },
    duplicates() {
      const duplicates = []
      const arr = this.state.slice().sort()
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i].trim() && arr[i + 1] == arr[i]) duplicates.push(arr[i])
      }
      return duplicates
    }
  },
  data() {
    return {
      state: [], // this.items,
      item_name: '' // `${this.name}.$each.`
    }
  },
  mounted() {
    this.item_name = `${this.name}.$each.`
    setTimeout(() => {
      this.state = this.items
      if (!this.state || !this.state.length) this.addItem()
    }, 300)
  },
  methods: {
    canAdd() {
      return !!this.state[this.last] && ![...this.errors(this.item_name + this.last)].length
    },
    addItem() {
      this.state.push('')
      this.onChange()
    },
    removeItem(index) {
      this.state.splice(index, 1)
      if (!this.state.length) this.addItem()
      this.onChange()
    },
    onChange() {
      //return this.state
      this.$emit('arrayChanged', this.state)
    }
  }
}
</script>
